import * as React from 'react';
import { RichText } from 'prismic-reactjs';

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
  withStyles,
  WithStyles,
} from '@nelnet/unifi-components-react';

import styles from './styles';

interface ISummaryGroupProps extends WithStyles<typeof styles> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  summary_group: Array<any>;
}

const SummaryGroup: React.FunctionComponent<ISummaryGroupProps> = ({ classes, summary_group }) => {
  return (
    <Grid container>
      {summary_group.map((item, i) => {
        const summary_background_color = item.summary_background_color.slice(-7);
        const summary_font_color = item.summary_font_color.slice(-7);
        const link = item.link._meta.uid;
        return (
          <React.Fragment key={i}>
            <Grid item sm={12} md={6}>
              <Card
                style={{
                  backgroundColor: `${summary_background_color}`,
                  color: `${summary_font_color}`,
                }}
                className={classes.padding}
              >
                {item.summary_image ? (
                  <CardMedia
                    image={item.summary_image.url}
                    title={item.summary_image.alt}
                    className={classes.icon}
                  />
                ) : null}
                <CardContent>
                  <Typography variant="body1" margin={{ bottom: 4 }} className="small">
                    {RichText.asText(item.summary_text)}
                  </Typography>
                  <Link
                    href={`/${link}/`}
                    underline="always"
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ color: `${summary_font_color}` }}
                  >
                    {RichText.asText(item.link_text)}
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
};

export default withStyles(styles)(SummaryGroup);
