import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    icon: {
      height: '125px',
      width: '125px',
      margin: 'auto',
    },
    padding: {
      padding: `${spacing(4)}px ${spacing(1)}px ${spacing(4)}px`,
      [breakpoints.up('md')]: {
        padding: `${spacing(8)}px 100px ${spacing(8)}px`,
      },
      [breakpoints.up('lg')]: {
        padding: `${spacing(8)}px 225px ${spacing(8)}px`,
      },
    },
  });

export default styles;
