import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    orderedList: {
      fontWeight: 700,
      '& li': {
        margin: '0 1rem',
      },
    },
    container: {
      margin: `${spacing(4)}px 0`,
      [breakpoints.up('md')]: {
        margin: `${spacing(8)}px 0`,
      },
    },
    unorderedList: {
      paddingLeft: 0,
      '& > h2': {
        [breakpoints.up('md')]: {
          marginTop: `${spacing(6)}px`,
        },
      },
    },
    leftAlignWithImage: {
      textAlign: 'left',
      [breakpoints.down('sm')]: {
        width: '100%',
        '& > p': {
          padding: '0 1rem',
        },
      },
      '& > h3': {
        fontSize: '1.5625rem',
        lineHeight: '1.875',
        letterSpacing: '0',
        textTransform: 'none',
        fontWeight: 400,
        margin: 0,
      },
      '& > ul': {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        '& > li': {
          padding: `${spacing(2)}px 0`,
        },
      },
    },
    leftAlign: {
      padding: `${spacing(0)}px ${spacing(6)}px`,
      textAlign: 'center',
      // similar styling to Divider component (just need it to be vertical)
      flexShrink: 0,
      [breakpoints.up('lg')]: {
        padding: `${spacing(0)}px 175px`,
        textAlign: 'left',
      },
    },
    border: {
      borderTop: '3px solid rgba(0, 0, 0, 0.12)',
      [breakpoints.up('md')]: {
        borderTop: 'none',
        paddingTop: 0,
        borderLeft: '3px solid rgba(0, 0, 0, 0.12)',
      },
      '& h2': {
        marginTop: '1rem',
        [breakpoints.up('md')]: {
          marginTop: 0,
        },
      },
    },
    bold: {
      fontWeight: 900,
      padding: `${spacing(1)}px ${spacing(6)}px ${spacing(0)}px`,
      textAlign: 'center',
      // similar styling to Divider component (just need it to be vertical)
      flexShrink: 0,
      [breakpoints.up('lg')]: {
        padding: `${spacing(2)}px 175px ${spacing(0)}px`,
        textAlign: 'left',
      },
    },
    fullWidth: {
      width: '100%',
      listStyle: 'none',
    },
    img: {
      height: '320px',
      width: '300px',
      [breakpoints.up('sm')]: {
        height: '435px',
        width: '455px',
      },
      [breakpoints.up('md')]: {
        height: '635px',
        width: '655px',
      },
    },
    rightPadding: {
      padding: '0 1rem',
      [breakpoints.up('lg')]: {
        padding: `${spacing(6)}px 4rem ${spacing(4)}px 0`,
      },
    },
  });

export default styles;
