import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    sideMargins: {
      padding: `${spacing(0)}px ${spacing(2)}px ${spacing(0)}px`,
      [breakpoints.up('lg')]: {
        padding: `${spacing(0)}px 200px ${spacing(0)}px`,
      },
    },
    imageGroup: {
      padding: `${spacing(6)}px ${spacing(2)}px ${spacing(0)}px`,
      width: '100%',
      [breakpoints.up('lg')]: {
        padding: `${spacing(10)}px ${spacing(10)}px ${spacing(8)}px`,
        width: '62%',
      },
    },
    image: {
      padding: `${spacing(4)}px ${spacing(2)}px ${spacing(6)}px`,
      [breakpoints.down('sm')]: {
        display: 'none',
      },
      [breakpoints.up('md')]: {
        display: 'block',
      },
      [breakpoints.up('lg')]: {
        padding: `${spacing(6)}px ${spacing(6)}px ${spacing(4)}px 0`,
      },
    },
    connectLink: {
      backgroundColor: '#AED136',
      fontFamily: 'Red Hat Display',
      color: '#000000',
      fontSize: '0.8125rem',
      fontWeight: 700,
      height: '45px',
      letterSpacing: '2px',
      lineHeight: '1',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '160px',
      marginTop: '1rem',
      padding: '12px 16px',
      '&:hover': {
        backgroundColor: '#799225',
      },
    },
  });

export default styles;
