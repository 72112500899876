import * as React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import {
  Button,
  Grid,
  Link,
  Paper,
  Typography,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';

import styles from './styles';

interface ICalloutGroupProps extends WithStyles<typeof styles> {
  title: RichTextBlock[];
  subtitle: RichTextBlock[];
  image_url?: string;
  image_alt?: string;
  button_text?: RichTextBlock[];
  button_style?: 'text' | 'outlined' | 'contained';
  button_url?: string;
}

const CalloutGroup: React.FunctionComponent<ICalloutGroupProps> = ({
  classes,
  title,
  subtitle,
  image_url,
  image_alt,
  button_text,
  button_style,
  button_url,
}) => {
  if (!image_url) {
    if (button_text) {
      return (
        <Grid container padding={{ top: 4, bottom: 4 }}>
          <Grid item xs={12} margin={{ bottom: 0 }} className={classes.sideMargins}>
            <Typography variant="h2">{RichText.asText(title)}</Typography>
            <Typography variant="body1" margin={{ bottom: 4 }} className="large">
              {RichText.asText(subtitle)}
            </Typography>
            {button_text ? (
              <Link
                underline="none"
                href={button_url}
                className={classes.connectLink}
                onClick={() => window.scrollTo(0, 0)}
              >
                {RichText.asText(button_text)}
              </Link>
            ) : null}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Paper padding={4}>
          <Grid container padding={{ top: 4, bottom: 4 }}>
            <Grid item xs={12} margin={{ bottom: 0 }} className={classes.sideMargins}>
              <Typography variant="h2">{RichText.asText(title)}</Typography>
              <Typography variant="body1" className="large">
                {RichText.asText(subtitle)}
              </Typography>
              {button_text ? (
                <Button variant={button_style} href={button_url}>
                  {RichText.asText(button_text)}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      );
    }
  } else {
    return (
      <Paper padding={{ top: 2, right: 4, bottom: 4, left: 4 }}>
        <Grid container>
          <Grid item xs={12} md={6} lg={7} margin={{ x: 'auto' }} className={classes.imageGroup}>
            <Typography variant="h2">{RichText.asText(title)}</Typography>
            <Typography variant="body1" className="large">
              {RichText.asText(subtitle)}
            </Typography>
            {button_text ? (
              <Button variant={button_style} href={button_url}>
                {RichText.asText(button_text)}
              </Button>
            ) : null}
          </Grid>
          <Grid item xs>
            {image_url ? (
              <Link underline="none">
                <img src={image_url} alt={image_alt} className={classes.image} />
              </Link>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }
};

export default withStyles(styles)(CalloutGroup);
