import { createStyles, Theme } from '@nelnet/unifi-components-react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    background: {
      minHeight: '375px',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'left top',
      padding: `${spacing(2)}px 0 ${spacing(6)}px 0`,
      [breakpoints.up('sm')]: {
        padding: `${spacing(10)}px ${spacing(10)}px ${spacing(10)}px`,
      },
      '& div > h1': {
        [breakpoints.down('xs')]: {
          fontSize: '2.25rem',
        },
      },
    },
    fullWidth: {
      minHeight: '375px',
      width: '100%',
      padding: `${spacing(6)}px ${spacing(2)}px ${spacing(2)}px`,
      [breakpoints.up('sm')]: {
        padding: `${spacing(6)}px ${spacing(10)}px ${spacing(2)}px`,
      },
      '& div > h1': {
        [breakpoints.down('xs')]: {
          fontSize: '2.25rem',
        },
      },
    },
    icon: {
      height: '175px',
      width: '175px',
      margin: 'auto',
    },
    twoColumn: {
      minHeight: '375px',
      position: 'relative',
      '& div > h1': {
        [breakpoints.down('xs')]: {
          fontSize: '2.25rem',
        },
      },
    },
    image: {
      position: 'relative',
      margin: 'auto',
      zIndex: 1,
      top: '10%',
      display: 'block',
      width: '50%',
      [breakpoints.up('sm')]: {
        position: 'absolute',
        maxWidth: '22rem',
        paddingLeft: '4rem',
        top: '6%',
      },
      [breakpoints.up('md')]: {
        paddingLeft: '8rem',
        maxWidth: '26rem',
        top: '4%',
      },
      [breakpoints.up('lg')]: {
        paddingLeft: '10rem',
        width: 'auto',
        maxWidth: 'unset',
        top: '4%',
      },
    },
    body: {
      padding: '2.5rem 1rem 2.5rem 1.5rem',
      [breakpoints.up('sm')]: {
        marginLeft: '48%',
        padding: '0.5rem 1.5rem',
      },
      [breakpoints.up('md')]: {
        margin: '10% 0 0',
        padding: '0 4rem 2rem 22%',
      },
      [breakpoints.up('lg')]: {
        marginTop: '5rem',
        padding: '2rem 8rem 2rem 14rem',
      },
      '& p.extra-large': {
        [breakpoints.down('sm')]: {
          fontSize: '1.75rem',
          padding: '1rem',
        },
        [breakpoints.up('md')]: {
          fontSize: '2.25rem',
        },
        [breakpoints.up('lg')]: {
          fontSize: '2.8125rem',
        },
      },
      '& h1.small': {
        fontWeight: 500,
        paddingBottom: '1rem',
        [breakpoints.down('sm')]: {
          padding: '1rem 1rem 0',
        },
      },
    },
    noIcon: {
      minHeight: '375px',
      padding: `80px 0 ${spacing(6)}px 0`,
      [breakpoints.up('sm')]: {
        padding: `125px ${spacing(10)}px ${spacing(10)}px`,
      },
      '& div > h1': {
        [breakpoints.down('xs')]: {
          fontSize: '2.25rem',
        },
      },
    },
  });

export default styles;
