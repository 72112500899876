import * as React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import {
  Grid,
  Link,
  Typography,
  WithStyles,
  withStyles,
  Paper,
} from '@nelnet/unifi-components-react';

import styles from './styles';

interface IListGroupProps extends WithStyles<typeof styles> {
  process_title?: RichTextBlock[];
  process_subtitle?: RichTextBlock[];
  image_url?: string;
  image_alt?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  process_group?: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mindset_header?: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mindset_group?: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skills_header?: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  skills_group?: Array<any>;
  slug?: string;
}

const ListGroup: React.FunctionComponent<IListGroupProps> = ({
  classes,
  process_title,
  process_subtitle,
  image_url,
  image_alt,
  process_group,
  mindset_header,
  mindset_group,
  skills_header,
  skills_group,
}) => {
  if (process_title) {
    return (
      <Paper>
        <Typography padding={{ top: 7 }} variant="h2">
          {RichText.asText(process_title)}
        </Typography>
        <Typography variant="body1" className="small">
          {RichText.asText(process_subtitle)}
        </Typography>
        <Grid container>
          <Grid item xs={12} md={8} padding={{ top: 6 }}>
            {image_url ? (
              <Link underline="none">
                <img className={classes.img} src={image_url} alt={image_alt} />
              </Link>
            ) : null}
          </Grid>
          <Grid item xs className={classes.rightPadding}>
            {process_group.map((process_item, i) => {
              return (
                <React.Fragment key={i}>
                  <div className={classes.leftAlignWithImage}>
                    {RichText.render(process_item.process_group_content)}
                  </div>
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return (
      <>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={6} margin={{ bottom: 2 }}>
            <Typography variant="h2">{RichText.asText(mindset_header)}</Typography>
            <ul className={classes.unorderedList}>
              {mindset_group.map((mindset_item, i) => {
                return (
                  <React.Fragment key={i}>
                    <li className={classes.fullWidth}>
                      <Typography variant="body1" className={classes.bold}>
                        {RichText.asText(mindset_item.mindset_title)}
                      </Typography>
                      <Typography variant="body1" className={classes.leftAlign}>
                        {RichText.asText(mindset_item.mindset_item)}
                      </Typography>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs margin={0} className={classes.border}>
            <Typography variant="h2">{RichText.asText(skills_header)}</Typography>
            <ul className={classes.unorderedList}>
              {skills_group.map((skills_item, i) => {
                return (
                  <React.Fragment key={i}>
                    <li className={classes.fullWidth}>
                      <Typography variant="body1" className={classes.bold}>
                        {RichText.asText(skills_item.skills_title)}
                      </Typography>
                      <Typography variant="body1" className={classes.leftAlign}>
                        {RichText.asText(skills_item.skills_item)}
                      </Typography>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default withStyles(styles)(ListGroup);
