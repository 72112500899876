import * as React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Link,
  Typography,
  WithStyles,
  withStyles,
} from '@nelnet/unifi-components-react';
import styles from './styles';

interface IHeroProps extends WithStyles<typeof styles> {
  background_url?: string;
  image_url?: string;
  image_alt?: string;
  icon_url?: string;
  icon_alt?: string;
  title: RichTextBlock[];
  subtitle?: RichTextBlock[];
  page_color?: string;
  page_font_color?: string;
}

const Hero: React.FunctionComponent<IHeroProps> = ({
  classes,
  background_url,
  image_url,
  image_alt,
  icon_url,
  icon_alt,
  title,
  subtitle,
  page_color,
  //TODO: figure out how to prevent this variable from being needed... font color should be set according to background color automagically
  page_font_color,
}) => {
  if (background_url) {
    return (
      <Card
        className={classes.background}
        margin={{ bottom: 0 }}
        style={{
          backgroundImage: `url(${background_url})`,
          color: `${page_font_color}`,
        }}
      >
        <CardContent>
          <Typography variant="h1" padding={{ top: 6 }}>
            {RichText.asText(title)}
          </Typography>
          {subtitle ? (
            <Typography variant="body1" className="small">
              {RichText.asText(subtitle)}
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    );
  } else if (icon_url) {
    return (
      <Card
        className={classes.fullWidth}
        margin={{ bottom: 1 }}
        style={{
          backgroundColor: `${page_color}`,
          color: `${page_font_color}`,
        }}
      >
        {icon_url ? <CardMedia image={icon_url} title={icon_alt} className={classes.icon} /> : null}
        <CardContent>
          <Typography variant="h1">{RichText.asText(title)}</Typography>
          {subtitle ? (
            <Typography variant="body1" className="small">
              {RichText.asText(subtitle)}
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    );
  } else if (image_url) {
    return (
      <>
        <Grid
          container
          className={classes.twoColumn}
          style={{ backgroundColor: `${page_color}`, color: `${page_font_color}` }}
        >
          <Grid item xs={12} md={3}>
            {image_url ? (
              <Link underline="none">
                <img src={image_url} alt={image_alt} className={classes.image} />
              </Link>
            ) : null}
          </Grid>
          <Grid item xs className={classes.body}>
            <Typography variant="h1" align="left" className="small">
              {RichText.asText(title)}
            </Typography>
            {subtitle ? (
              <Typography variant="body1" align="left" className="extra-large">
                {RichText.asText(subtitle)}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <Card
        className={classes.noIcon}
        style={{
          backgroundColor: `${page_color}`,
          color: `${page_font_color}`,
        }}
      >
        <CardContent>
          <Typography variant="h1">{RichText.asText(title)}</Typography>
          {subtitle ? (
            <Typography variant="body1" className="small">
              {RichText.asText(subtitle)}
            </Typography>
          ) : null}
        </CardContent>
      </Card>
    );
  }
};

export default withStyles(styles)(Hero);
