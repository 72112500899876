import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { RichText, RichTextBlock } from 'prismic-reactjs';

import { graphql } from 'gatsby';

import LayoutFrame from '../components/layout/LayoutFrame';
import { Typography } from '@nelnet/unifi-components-react';
import Hero from '../components/layout/Hero';
import CalloutGroup from '../components/layout/CalloutGroup';
import ListGroup from '../components/layout/ListGroup';
import SummaryGroup from '../components/layout/SummaryGroup';

interface IIndexProps {
  data: {
    prismic: {
      allHomepages: {
        edges: [
          {
            node: {
              _meta: {
                uid: string;
                type: string;
              };
              background_url: {
                url: string;
              };
              image: {
                url: string;
                alt: string;
              };
              mission_title: RichTextBlock[];
              description: RichTextBlock[];
              content_title: RichTextBlock[];
              content_subtitle: RichTextBlock[];
              primary_page_color: string;
              page_font_color: string;
              call_out_title: RichTextBlock[];
              call_out_text: RichTextBlock[];
              call_out_image: {
                url: string;
                alt: string;
              };
              call_out_button: RichTextBlock[];
              call_out_button_style: 'text' | 'outlined' | 'contained';
              call_out_link: {
                url: string;
              };
              process_title: RichTextBlock[];
              process_intro: RichTextBlock[];
              process_image: {
                url: string;
                alt: string;
              };
              process_group: [
                {
                  group_title: RichTextBlock[];
                },
              ];
              mindset_header: [
                {
                  text: string;
                },
              ];
              mindset_group: [
                {
                  mindset_title: {
                    text: string;
                  };
                  mindset_item: {
                    text: string;
                  };
                },
              ];
              skills_header: [
                {
                  text: string;
                },
              ];
              skills_group: [
                {
                  skills_title: {
                    text: string;
                  };
                  skills_item: {
                    text: string;
                  };
                },
              ];
              summary_group: [
                {
                  summary_image: {
                    url: string;
                    alt: string;
                  };
                  summary_background_color: string;
                  summary_font_color: string;
                  summary_text: {
                    text: string;
                  };
                  link: {
                    _linkType: string;
                    _meta: {
                      uid: string;
                    };
                  };
                  link_text: string;
                },
              ];
            };
          },
        ];
      };
      allFooters: {
        edges: [
          {
            node: {
              title: RichTextBlock[];
              subtitle: RichTextBlock[];
              button_text: RichTextBlock[];
              button_style: 'text' | 'outlined' | 'contained';
              button_link: {
                text: string;
              };
            };
          },
        ];
      };
    };
  };
}

const Index: React.FunctionComponent<IIndexProps> = ({ data }) => {
  // Required check for no data being returned
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
  const footerDoc = data.prismic.allFooters.edges.slice(0, 1).pop();

  const uid = doc.node._meta.uid;
  const slug = uid === 'home' ? '/' : `/${uid}/`;
  const content_title = doc.node.content_title;
  const content_subtitle = doc.node.content_subtitle;

  if (!doc) {
    return (
      <LayoutFrame currPath={slug}>
        <Helmet>
          <title>{RichText.asText(content_title)}</title>
        </Helmet>
        <Typography variant="h2" margin={{ bottom: 3 }}>
          {RichText.asText(content_title)}
        </Typography>
      </LayoutFrame>
    );
  }

  const {
    mission_title,
    description,
    call_out_title,
    call_out_text,
    call_out_button,
    call_out_button_style,
    process_title,
    process_intro,
  } = doc.node;

  const background_url = doc.node.background_url ? doc.node.background_url.url : null;
  const image_url = doc.node.image.url;
  const image_alt = doc.node.image.alt;
  const page_font_color = doc.node.page_font_color.slice(-7);
  const page_color = doc.node.primary_page_color.slice(-7);
  const call_out_image_url = doc.node.call_out_image.url;
  const call_out_image_alt = doc.node.call_out_image.alt;
  const call_out_link = doc.node.call_out_link.url;

  const process_image_url = doc.node.process_image.url;
  const process_image_alt = doc.node.process_image.alt;
  const process_group = doc.node.process_group;
  const mindset_header = doc.node.mindset_header;
  const mindset_group = doc.node.mindset_group;
  const skills_header = doc.node.skills_header;
  const skills_group = doc.node.skills_group;
  const summary_group = doc.node.summary_group;

  const { title, subtitle, button_text, button_style } = footerDoc.node;
  const button_link = footerDoc.node.button_link[0].text;

  return (
    <LayoutFrame currPath={slug}>
      <Helmet>
        <title>{RichText.asText(content_title)}</title>
      </Helmet>
      <Hero
        title={content_title}
        subtitle={content_subtitle}
        background_url={background_url}
        image_url={image_url}
        image_alt={image_alt}
        page_color={page_color}
        page_font_color={page_font_color}
      ></Hero>
      <ListGroup
        process_title={process_title}
        process_subtitle={process_intro}
        image_url={process_image_url}
        image_alt={process_image_alt}
        process_group={process_group}
      ></ListGroup>
      <ListGroup
        mindset_header={mindset_header}
        mindset_group={mindset_group}
        skills_header={skills_header}
        skills_group={skills_group}
      ></ListGroup>
      <CalloutGroup title={mission_title} subtitle={description}></CalloutGroup>
      <SummaryGroup summary_group={summary_group}></SummaryGroup>
      <CalloutGroup
        title={call_out_title}
        subtitle={call_out_text}
        image_url={call_out_image_url}
        image_alt={call_out_image_alt}
        button_text={call_out_button}
        button_style={call_out_button_style}
        button_url={call_out_link}
      ></CalloutGroup>
      <CalloutGroup
        title={title}
        subtitle={subtitle}
        button_text={button_text}
        button_style={button_style}
        button_url={button_link}
      ></CalloutGroup>
    </LayoutFrame>
  );
};

export default Index;

export const query = graphql`
  query IndexQuery {
    prismic {
      allHomepages {
        edges {
          node {
            _meta {
              uid
            }
            background_url
            image
            mission_title
            description
            content_title
            content_subtitle
            primary_page_color
            page_font_color
            call_out_title
            call_out_text
            call_out_image
            call_out_button
            call_out_button_style
            call_out_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            process_title
            process_intro
            process_image
            process_image
            process_group {
              process_group_content
            }
            mindset_header
            mindset_group {
              mindset_title
              mindset_item
            }
            skills_header
            skills_group {
              skills_title
              skills_item
            }
            summary_group {
              summary_image
              summary_background_color
              summary_font_color
              summary_text
              link {
                _linkType
                ... on PRISMIC_Capabilities {
                  _meta {
                    uid
                  }
                  title
                }
                ... on PRISMIC_Guidelines {
                  _meta {
                    uid
                  }
                  title
                }
              }
              link_text
            }
          }
        }
      }
      allFooters {
        edges {
          node {
            title
            subtitle
            button_text
            button_style
            button_link
          }
        }
      }
    }
  }
`;
